
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AdContentDetail": [
      "AdAppOpening",
      "AdCommunityFeedArticle",
      "AdContentDefault",
      "AdForegroundPopup"
    ],
    "ArticleCommentContent": [
      "ArticleContentText"
    ],
    "ArticleContent": [
      "ArticleContentAsset",
      "ArticleContentBackTestingProject",
      "ArticleContentImage",
      "ArticleContentOgTag",
      "ArticleContentPortfolio",
      "ArticleContentText"
    ],
    "ArticleContentSubTextComponent": [
      "ArticleContentTextSubTextComponentCrypto",
      "ArticleContentTextSubTextComponentStock",
      "ArticleContentTextSubTextComponentText",
      "ArticleContentTextSubTextComponentUser"
    ],
    "ArticleTag": [
      "ArticlePortfolioTopic",
      "ArticleStockTag"
    ],
    "BackTestingAsset": [
      "BackTestingCrypto",
      "BackTestingStock"
    ],
    "BackTestingCase": [
      "BackTestingCrypto",
      "BackTestingPortfolio",
      "BackTestingStock"
    ],
    "FollowItem": [
      "FollowCompany",
      "FollowCryptoCurrency",
      "FollowUser"
    ],
    "InvestingDiaryLog": [
      "InvestingDiaryDepositWithdrawalLog",
      "InvestingDiaryExchangeCurrencyLog",
      "InvestingDiaryMemoLog",
      "InvestingDiaryTradeLog"
    ]
  }
};
      export default result;
    