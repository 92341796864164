import '@/config'
import('@/utils/route-change-animation')

import * as React from 'react'
import type { AppContext, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { first } from 'lodash'
import { NormalizedCacheObject } from '@apollo/client'
import dynamic from 'next/dynamic'

import useScriptsDelayLoad from '@/components/head/scripts-delay-hook'
import pageVisibilityChangeHandler from '@/utils/browser/page-visibility-change-handler'
import useRestoreScrollHeight from '@/utils/browser/restore-scroll-height-hook'
import { initFiatCurrencies } from '@/service/currency/fetch-currencies'
// import { ActionCableProvider } from '@/apollo-client/action-cable-provider'

const GlobalStyle = dynamic(() => import('../style/global-style'))
const Metas = dynamic(() => import('@/components/head/metas'))
const Layout = dynamic(() => import('@/components/layout'))
const I18nProvider = dynamic(() => import('@/service/locale/locale-provider'))
const ThirdParty = dynamic(() => import('@/components/third-party'))
const ApolloProvider = dynamic(() => import('../apollo-client/apollo-provider'))
const Toast = dynamic(() => import('@/components/common/toast'), { ssr: false })
const SignInModal = dynamic(() => import('@/components/shape/sign-in-modal'), { ssr: false })

const regex = new RegExp(/\/([\w+-]+)/)

enum NEED_MERGE_GLOBAL_STYLES_PAGE {
  SANGSANGIN = '/sangsangin',
}

function App({ Component, pageProps }: AppProps<{ initialApolloState?: NormalizedCacheObject }>) {
  const { asPath } = useRouter()
  const matched = asPath.match(regex)

  useScriptsDelayLoad()
  useRestoreScrollHeight()

  React.useMemo(() => {
    typeof global.window !== 'undefined' && initFiatCurrencies()
  }, [])

  const provideMergeGlobalStyles = React.useMemo(() => {
    switch (first(matched)) {
      case NEED_MERGE_GLOBAL_STYLES_PAGE.SANGSANGIN:
        return {
          body: {
            'background-color': 'var(--color-sangsangin-white)',
          },
        }
      default:
        return undefined
    }
  }, [matched])

  // 한시간 지난 상태에서 브라우저 탭이 전환되어 활성화된 경우 새로고침
  // 캐시 사용을 강제로 해제하기 위함
  React.useEffect(() => {
    const loadDatePlusOneHours = new Date()
    loadDatePlusOneHours.setHours(loadDatePlusOneHours.getHours() + 1)
    pageVisibilityChangeHandler((isVisibility) => {
      if (isVisibility && loadDatePlusOneHours < new Date()) {
        window.location.reload()
      }
    })
  }, [])

  return (
    <>
      {/*<ActionCableProvider>*/}
      <GlobalStyle mergeGlobalStyles={provideMergeGlobalStyles} />
      <ApolloProvider initialApolloState={pageProps?.initialApolloState}>
        <I18nProvider>
          <Metas />
          <ThirdParty>
            <Layout>
              <Component {...pageProps} />
              <SignInModal />
            </Layout>
          </ThirdParty>
        </I18nProvider>
      </ApolloProvider>
      <Toast />
      {/*</ActionCableProvider>*/}
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  const userAgent = ctx.req?.headers['user-agent']
  const theme = ctx.query?.theme || 'light'

  pageProps = { ...pageProps, userAgent, theme }

  return { pageProps }
}

export default App
