import { InMemoryCache } from '@apollo/client'

import mergeWithPagination from '@/utils/apollo-client/type-policies/merge-with-pagination'
import readWithPagination from '@/utils/apollo-client/type-policies/read-with-pagination'

import introspection from './api-server-possible-types'

export default function apiServerApolloClientCache() {
  return new InMemoryCache({
    // UNION 타입의 관계를 아폴로 클라이언트가 이해할 수 있도록 설정
    // codegen 으로 가져옴에 유의
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      AssetTagTitle: {
        // id만으로는 counterCurrency 정보가 다른 경우를 식별할 수 없어서 별도의 key를 등록
        // FeedFilteredByTag 쿼리 언어 변경시에 캐시값으로 올때 counterCurrency에 문제가 생기는 현상 대응
        keyFields: ['id', 'counterCurrency', ['symbol']],
      },
      CryptoExchange: {
        // id가 같아서 language 매개변수에 따라서 name이 다르게 내려오는 현상 대응. API에 language type으로 내려주면 해결 가능
        keyFields: ['id', 'name'],
      },
      SearchCompany: {
        keyFields: ['id', 'categoryType'],
      },
      // !CAUTION: API2 에서는 한국 주식 이름을 넘겨주지 않아서 임시로 클라이언트에서 처리하는 로직
      CompanyNameLanguage: {
        merge(existing, incoming) {
          const ko = !incoming?.ko && existing?.ko ? existing?.ko : incoming?.ko
          const en = !incoming?.en && existing?.en ? existing?.en : incoming?.ko
          return !ko && !en ? incoming : { ...incoming, ko, en }
        },
      },
      Article: {
        fields: {
          content: {
            // merge(existing, incoming) {
            //   return incoming
            // },
            // 아티클 수정시 content 구조가 다른 경우 경고(warning 어쩌고) 대응
            merge: false, // 위 형태의 속기(incoming으로 대체되는게 default 로직이지만 exsting과 incoming 구조가 다른 경우에는 개발모드에서 console.warning 출력되므로 만약 문제가 없다고 개발자가 확신한다면 경고 발생하지 않도록 해주는게 BEST)
          },
          portfolioTopics: {
            // 아티클 수정시 포폴 공유 토픽 다른 토픽으로 수정할 경우 경고(warning 어쩌고) 대응
            merge: false,
          },
        },
      },
      UserPortfolio: {
        fields: {
          assets: { merge: false },
        },
      },
      FavoriteItemCategory: {
        fields: {
          items: { merge: false },
        },
      },
      Query: {
        fields: {
          timelineFeed: {
            keyArgs: ['language', 'feedTopicId', 'portfolioTopicId', 'includeInfluencerArticle'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          feedFilteredByTag: {
            keyArgs: ['assetTagId'],
            merge(existing = { articles: [], assetTagTitle: {} }, incoming) {
              const cacheArticles: { [key in string]: true } = {}
              const articles = [...existing.articles, ...incoming.articles]?.reduce(
                (articles: { __ref: string }[], article: { __ref: string }) => {
                  if (!cacheArticles[article?.['__ref']]) {
                    cacheArticles[article?.['__ref']] = true
                    articles.push(article)
                  }
                  return articles
                },
                [],
              )

              return {
                articles,
                assetTagTitle: incoming.assetTagTitle,
              }
            },
          },
          userArticles: {
            keyArgs: ['userId'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          myArticles: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          myCommentedArticles: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          myBookmarkedArticles: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          myFollowings: {
            keyArgs: ['itemType', 'language', 'sortOrderType'],
            merge(existing = { items: [], totalCount: 0 }, incoming) {
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
                totalCount: incoming.totalCount ?? existing.totalCount,
              }
            },
          },
          myFollowingFeed: {
            keyArgs: ['userFollowingId', 'language'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          userFollowers: {
            keyArgs: ['userId', 'language'],
            merge(existing = { items: [], totalCount: 0 }, incoming) {
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
                totalCount: incoming.totalCount ?? existing.totalCount,
              }
            },
          },
          userFollowings: {
            keyArgs: ['itemType', 'language', 'userId'],
            merge(existing = { items: [], totalCount: 0 }, incoming) {
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
                totalCount: incoming.totalCount ?? existing.totalCount,
              }
            },
          },
          recommendedFollows: {
            keyArgs: ['itemType', 'language'],
            merge(existing = { items: [], totalCount: 0 }, incoming) {
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
                totalCount: incoming.totalCount ?? existing.totalCount,
              }
            },
          },
          searchFollows: {
            keyArgs: ['itemType', 'language', 'keyword'],
            merge(existing = { items: [], totalCount: 0 }, incoming) {
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
                totalCount: incoming.totalCount ?? existing.totalCount,
              }
            },
          },
          searchCommunityArticles: {
            keyArgs: ['keyword', 'language'],
            merge(existing = { articles: [] }, incoming) {
              return {
                articles: [...existing.articles, ...incoming.articles],
              }
            },
          },
          searchCommunityUsers: {
            keyArgs: ['keyword'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          blockedUsers: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          searchUsersInArticle: {
            keyArgs: ['articleId', 'keyword'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          userPublicPortfolios: {
            keyArgs: ['userId'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          myPublicPortfolios: {
            keyArgs: false,
            merge(existing = { myPublicPortfolios: [] }, incoming) {
              return {
                ...incoming,
                myPublicPortfolios: [
                  ...existing.myPublicPortfolios,
                  ...incoming.myPublicPortfolios,
                ],
              }
            },
          },
          publicFavoriteItems: {
            keyArgs: ['favoriteItemCategoryId'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          investingInsightTags: {
            read: readWithPagination,
            merge: mergeWithPagination,
          },
          investingInsights: {
            keyArgs: ['language', 'tagId'],
            read: readWithPagination,
            merge: mergeWithPagination,
          },
          dividendCalendarForExternalChannelWithPagination: {
            keyArgs: ['date', 'sortType'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  })
}
