import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { Consumer } from '@rails/actioncable'

export type CreateApolloClientProps = {
  isServer?: boolean
  cable?: Consumer | null
  token?: string | null
}

type CreateApolloClient = ({
  isServer,
}: CreateApolloClientProps) => ApolloClient<NormalizedCacheObject>

export default function isomorphicApolloClient(createApolloClient: CreateApolloClient) {
  let apolloClient: ApolloClient<NormalizedCacheObject>
  return (
    initialState: NormalizedCacheObject | null = null,
    cable: Consumer | null = null,
  ): ApolloClient<NormalizedCacheObject> => {
    const isServer = typeof window === 'undefined'
    console.log('isomorphicApolloClient', { isServer, cable })
    const _apolloClient = apolloClient ?? createApolloClient({ isServer, cable })

    // initialState: cached fetch data on server side
    // SSR props -> initialState: apolloClient.cache.extract()
    if (initialState) {
      const cachedClientState = _apolloClient.cache.extract() // cached fetch data on client side
      // 단순히 전개 연산자로 merge 할 경우 같은 타입은 그저 initialState 값으로 덮어씌우는 형태여서 client cached data 삭제됨
      const store = Object.keys(initialState).reduce(
        (store, key) => {
          store[key] = store[key] ? { ...store[key], ...initialState[key] } : initialState[key]
          return store
        },
        { ...cachedClientState },
      )
      _apolloClient.cache.restore(store)
    }

    // always using fresh data when server side
    if (isServer) return _apolloClient

    if (cable == null) {
      apolloClient = apolloClient ?? _apolloClient
    } else {
      apolloClient = createApolloClient({ isServer, cable })
    }

    return apolloClient
  }
}
