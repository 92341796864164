import * as React from 'react'

export const isMobileSize = () => typeof global.window === 'undefined' || window.innerWidth < 768

const useResize = ({ onResize }: { onResize: (e: Event) => void }): void => {
  // width와 height 모두 변경됨에 유의
  React.useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])
}

// 렌더 후 모바일로 판정됬을 때 true
export const useMobileSize = (init: boolean | null = false) => {
  const [detectLoading, setDetectLoading] = React.useState(true)
  const [isMobile, setIsMobile] = React.useState(init)
  const handleResize = React.useCallback(() => {
    setIsMobile(isMobileSize())

    /* <Method-1> in resize-hook for mobile safari height
     * reference: { :root { --doc-height: 100%; }} in global-style.ts file
     * [Usage-1]
     * height: 100vh; // Fallback for browsers that do not support Custom Properties
     * height: var(--doc-height)
     */
    document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`)

    /* <Method-2> in resize-hook for mobile safari height
     * reference: .--doc-height { ...[some css] } in layout.ts file
     * [Usage-2]
     * className="--doc-height"
     */
    // First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])
  useResize({ onResize: handleResize })

  React.useEffect(() => {
    setDetectLoading(true)
    handleResize()
    setDetectLoading(false)
  }, [handleResize])
  return { isMobile, detectLoading }
}

// 렌더 후 데스크탑으로 판정됬을 때 true
export const useDesktopSize = (init: boolean | null = false) => {
  const [isDesktop, setIsDesktop] = React.useState(init)
  const handleResize = React.useCallback(() => {
    setIsDesktop(!isMobileSize())
  }, [])
  useResize({ onResize: handleResize })

  React.useEffect(() => {
    handleResize()
  }, [handleResize])

  return { isDesktop }
}

export default useResize
